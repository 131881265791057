// Read Me:
// These are Server Connection Options for the Jitsi library
// Best is to Keep them as a reference
// Just duplicate the file and name it serverConfig.ts to adjust to your likings

export const connectionOptions = {
  hosts: {
     domain: 'the-prdct.com',
     muc: 'conference.the-prdct.com', 
     focus: 'focus.the-prdct.com',
  }, 
  externalConnectUrl: 'https://meet.the-prdct.com/http-pre-bind',
  p2p: { 
     enabled: false, 
    //  preferH264: true, 
    //  disableH264: true, 
    //  useStunTurn: true,
  }, 
  useStunTurn: false,
  // bosh: `//the-prdct.com/http-bind?room=asdf`, /* deprecated */
  serviceUrl: `//the-prdct.com/http-bind`,  /* use this instead of bosh-param */
  // websocket: 'wss://the-prdct.com/xmpp-websocket?room=asdf',
  clientNode: 'http://jitsi.org/jitsimeet',
}

export const stageConnectionConfig = {
  hosts: {
     domain: 'the-prdct.com',
     muc: 'conference.the-prdct.com', 
     focus: 'focus.the-prdct.com',
  }, 
  externalConnectUrl: 'https://meet.the-prdct.com/http-pre-bind',
  useStunTurn: false,
  // bosh: `//the-prdct.com/http-bind?room=asdf`, /* deprecated */
  serviceUrl: `//the-prdct.com/http-bind`,  /* use this instead of bosh-param */
  // websocket: 'wss://the-prdct.com/xmpp-websocket', 
  clientNode: 'http://jitsi.org/jitsimeet'
}